























































































import {
  Component, PropSync, Vue, Watch,
} from 'vue-property-decorator';

import FlagshipProfilingModalViewModel
  from '@/vue-app/view-models/components/flagship/flagship-profiling/flagship-profiling-modal-view-model';

@Component({
  name: 'FlagshipProfilingModalSteps',
  components: {
    FlagshipProfilingStepIntroduction: () => import('@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingStepIntroduction.vue'),
    FlagshipProfilingStepIntroductionVideo: () => import('@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingStepIntroductionVideo.vue'),
    FlagshipProfilingStepGetReady: () => import('@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingStepGetReady.vue'),
    FlagshipProfilingStepAboutYou: () => import('@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingStepRetirementFund.vue'),
    FlagshipProfilingStepEconomicDependents: () => import('@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingStepEconomicDependents.vue'),
    FlagshipProfilingStepEmploymentSituation: () => import('@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingStepEmploymentSituation.vue'),
    FlagshipProfilingStepBudgetVideo: () => import('@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingStepBudgetVideo.vue'),
    FlagshipProfilingStepEmergencyFund: () => import('@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingStepEmergencyFund.vue'),
    FlagshipProfilingStepGoals: () => import('@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingStepGoals.vue'),
    FlagshipProfilingStepFinancialHabits: () => import('@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingStepFinancialHabits.vue'),
    FlagshipProfilingStepProcess: () => import('@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingStepProcess.vue'),
    FlagshipProfilingProjection: () => import('@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingProjection.vue'),
    FlagshipProfilingStepEmergencyFundVideo: () => import('@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingStepEmergencyFundVideo.vue'),
    FlagshipProfilingStepProjectionVideo: () => import('@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingStepProjectionVideo.vue'),
    FlagshipProfilingStepRetirementFund: () => import('@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingStepRetirementFund.vue'),
    FlagshipProfilingSowosPocket: () => import('@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingSowosPocket.vue'),
    FlagshipProfilingSowosWealth: () => import('@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingSowosWealth.vue'),
    FlagshipProfilingYouAlreadyHaveYourContractedProduct: () => import('@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingYouAlreadyHaveYourContractedProduct.vue'),
  },
})
export default class FlagshipProfilingModalSteps extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  flagship_modal_view_model = Vue.observable(
    new FlagshipProfilingModalViewModel(),
  );

  @Watch('synced_is_open')
  syncedIsOpenChange(synced_is_open: boolean) {
    if (synced_is_open) {
      this.flagship_modal_view_model.initialize();
    }
  }

  endProcess() {
    this.synced_is_open = false;
    this.flagship_modal_view_model.endProcess();
  }

  closeModal(recommended_investment_product = '') {
    this.synced_is_open = false;
    this.flagship_modal_view_model.setRecommendedInvestmentProduct(recommended_investment_product);
  }
}
